import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取成果列表 /ewi/guest/Product/PageProductList
export function pageProductList(isHome,page,size,title,typeId){
  return request({
    url: '/ewi/guest/Product/PageProductList',
    method: 'post',
    data: {
      isHome,
      page,
      size,
      title,
      typeId,
      systemNumber: systemNumber,
    }
  })
}

// 获取成果类型列表 /ewi/guest/Product/GetProductTypeList
export function getProductTypeList(){
  return request({
    url: '/ewi/guest/Product/GetProductTypeList',
    method: 'get',
    params: {
      systemNumber: systemNumber,
    }
  })
}

// 获取单个成果信息 /ewi/guest/Product/GetProductInfo
export function getProductInfo(id){
  return request({
    url: '/ewi/guest/Product/GetProductInfo',
    method: 'get',
    params: {
      id,
      systemNumber: systemNumber,
    }
  })
}
